<template>
  <el-card shadow="never">
    <el-row>
      <div class="date">日期：</div>
      <el-date-picker
          style="width: 240px"
          v-model="iqcDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
      <el-button type="primary" @click="exportData" :loading="loading" class="btn">导出IQC检验数据</el-button>
    </el-row>
  </el-card>
</template>

<script>
// import {currentDay, getLastMonth} from "@/utils/common";

import {currentDay} from "@/utils/common";

export default {
  name: "IqcDataReport",
  data() {
    return {
      iqcDate: currentDay(),
      loading: false
    }
  },
  methods: {
    exportData() {
      if (!this.iqcDate) {
        return this.$message.error('日期不能为空！');
      }
      this.loading = true;
      this.$axios.post('expReport/expIqcDataReport', this.iqcDate).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });
    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}

.date {
  display: inline-block;
  width: 120px;
  text-align: right;
}
</style>